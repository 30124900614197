<template>
    <div class="vx-row">
      <div class="vx-col w-full">

          <div v-if="cart_total.total_qty == 0" class="text-center">
            <div class="mb-3">
              <img :src="require('@/assets/images/pages/empty-cart-png.png')" class="h-64" alt="Empty Cart">
            </div>

            <vs-button color="primary" type="filled" to="/products">Go Shopping</vs-button>
          </div>
        <vx-card class="checkout" v-else title="Place order">
          <div>
            <small>*We will save this info in your browser to prevent repeating for next purchase.</small>
            <vs-input @change="update_user"  label="Full Name *" v-validate="'required'"  name="Full Name" v-model="user_data.name" class="mt-5 w-full" />
            <span class="text-danger text-sm"  v-show="errors.has('Full Name')">{{ errors.first('Full Name') }}</span>

            <vs-input @change="update_user" label="Mobile number *" type="number" v-validate="'required|digits:11'" placeholder=" 11 digit" name="Mobile Number" v-model="user_data.phone" class="mt-5 w-full" />
            <span class="text-danger text-sm" v-show="errors.has('Mobile Number')">{{ errors.first('Mobile Number') }}</span>

            <vs-textarea @change="update_user"
              v-model="user_data.address"
              v-validate="'required'"
              name="address"
              label="Address *"
              placeholder="Enter full address"
              class="w-full mt-5"/>
            <span v-show="errors.has('address')" class="text-danger">{{ errors.first('address') }}</span>
            <vs-textarea
              v-model="note"
              name="Note"
              label="Any note"
              placeholder="Any note regarding package"
              class="w-full mt-5"/>
          </div>
          <div>
            <div class="mb-1">
              <p :class="{'text-danger': !user_data.shipping}">Shipping <span class="text-success" v-if="$store.state.settings.free_shipping_enabled && cart_total.shipping !=0">(Save {{user_data.shipping.cost| currency}} by ordering more {{($store.state.settings.free_shipping_min_amount - cart_total.total) | currency}})</span></p>
              <ul class="ml-10">
                <li v-for="(method,key) in shipping_method" :key="key">
                  <vs-radio @change="update_user" v-validate="'required'"  name="shipping" v-model="shipping" :vs-value="JSON.stringify(method)">{{method.label}}</vs-radio>
                </li>
              </ul>
              <!--<v-select @input="update_user" :options="shipping_method" v-model="user_data.shipping" :clearable="false" />-->
            </div>
            <vs-divider />
            <div v-if="coupon" class="flex justify-between mt-2">
              <span  class="font-semibold">Coupon ({{coupon}}) <span class="text-primary cursor-pointer" @click="m_clearCoupon">Clear</span></span>
              <span class="font-medium text-primary cursor-pointer" @click="activePrompt=true">Change</span>
            </div>
            <div v-else class="flex justify-between mt-2">
              <span  class="font-semibold">Coupon</span>
              <span class="font-medium text-primary cursor-pointer" @click="activePrompt=true">Apply</span>
            </div>
            <vs-divider />
            <p class="font-semibold mb-3">Price Details</p>
            <div class="flex justify-between mb-2">
              <span class="text-dark">Sub Total</span>
              <span>{{cart_total.sub_total | currency}}</span>
            </div>
            <div class="flex justify-between mb-2">
              <span class="text-dark">Delivery Charges ({{user_data.shipping.label}})</span>
              <span v-if="cart_total.shipping"> {{cart_total.shipping|currency}}</span>
              <span v-else class="text-success">Free</span>
            </div>
            <vs-divider />
            <div v-if="cart_total.discount" class="flex justify-between mb-2">
              <span class="text-dark">Discount</span>
              <span class="text-success">{{cart_total.discount|currency}}</span>
            </div>
            <vs-divider />
            <div class="flex justify-between font-semibold mb-3">
              <span>Total</span>
              <span>{{cart_total.grand_total|currency}}</span>
            </div>
          </div>
          <div class="text-right">
            <vs-button color="success" @click="place_order" :disabled="!user_data.shipping" type="filled">{{user_data.shipping? 'Place Order':'Update Shipping'}}</vs-button>
          </div>
        </vx-card>
        <vs-prompt
          @cancel="updateCoupon"
          @close="updateCoupon"
          @accept="updateCoupon"
          title="Coupon"
          accept-text="Apply"
          :active.sync="activePrompt">
          <div class="con-exemple-prompt">
            <span>Enter you coupon code</span>
            <vs-input placeholder="Coupon code" type="text" vs-placeholder="Mobile number" v-model="coupon" class="mt-3 w-full" />
          </div>
        </vs-prompt>
      </div>
    </div>
</template>

<script>
  import vSelect from 'vue-select'
    export default {
      components: {
        'v-select': vSelect,
      },
        name: "Checkout",
        data(){
          return {
            user_data: this.$store.state.user,
            note:'',
            activePrompt:false,
            coupon: this.$store.state.eCommerce.coupon.code,
            shipping:JSON.stringify(this.$store.state.user.shipping)
          }
        },
      computed:{
        shipping_method(){
          return this.$store.state.settings.shipping.filter(item=>{
            return item.enabled
          })
        },
        cartItems() {
          return this.$store.state.eCommerce.cartItems.slice().reverse();
        },
        cart_total(){
          let sub_total=0,
            total_qty = 0,
            total=0,
            grand_total=0,
            discount=0,
            shipping=40,
            setting = this.$store.state.settings;
          this.cartItems.forEach(function (item) {
            sub_total+=item.cal_price*item.quantity;
            total_qty+=item.quantity;
          });
          if (Object.keys(this.$store.state.eCommerce.coupon).length>0){
            discount = parseFloat(this.$store.state.eCommerce.coupon.discount.amount);
          }
          total = sub_total-discount;

          if (setting.free_shipping_enabled && parseFloat(setting.free_shipping_min_amount)<parseFloat(total)){
            shipping = 0;
          }else if (this.$store.state.user.shipping){
            shipping = this.$store.state.user.shipping.cost;
          }
          if (total_qty>0)
            grand_total= total+parseFloat(shipping);


          return {
            total,
            total_qty,
            sub_total,
            grand_total,
            discount,
            shipping,
          }
        }
      },
      methods:{
          updateCoupon(){
            this.m_updateCoupon(this.coupon);
          },
          update_user(){
          this.user_data.shipping = JSON.parse(this.shipping);
            this.$store.commit('UPDATE_USER', this.user_data);
          },
          place_order(){
            let items=[];
            this.cartItems.forEach(function (item) {
              items.push({product_id:item.id,options:item.selected_options, qty:item.quantity});
            });
            this.$vs.loading();
            this.axios.post('order', {customer_data:{
                phone: this.user_data.phone,
                address: this.user_data.address,
                name: this.user_data.name,

              },
              "payment_method": "cod",
              "shipping_method": this.user_data.shipping.type,
              "terms_and_conditions": "on",
              item: items})
              .then(({data})=>{
                this.$vs.loading.close();
                this.m_pixel_Purchase({
                  value: data.total.amount,
                  currency: 'BDT',
                  contents: items,
                  content_type: 'product'
                })
                // if(data.length>0){
                  this.$vs.notify({
                    title:'Success',
                    text:'Order added successfully',
                    color:'success'
                  });
                  this.$root.$emit('hideCart');
                  this.$router.push('/view-order/'+data.id);
                  this.$store.commit('eCommerce/CLEAR_CART');
                  this.$store.commit('eCommerce/UPDATE_COUPON', []);



                // }

              }).catch(error => {
              this.$vs.loading.close();
              this.$vs.notify({
                tile:'Error',
                text: error.message,
                color:'danger',
              })
            });
          }
      }
    }
</script>

<style scoped>
  @media only screen and (min-width: 780px){
    .checkout {
        margin-right: 400px;
    }
  }

</style>
